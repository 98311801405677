import React, { Component } from 'react';

import { Container, H1 } from '../../components/index.js';

export class UserNotExistsError extends Component {
  render() {
    return (
      <div className="user-center">
        <Container section className="user m-50">
          <H1>
            <div className="icon-warn" role="img" aria-label=" Not Authorized">
              <i class="fas fa-exclamation-triangle"></i>
            </div>
            <div className="error-head">Access Denied</div>
            <div className="error-title">You currently do not have system access.</div>
          </H1>
          <H1 className="mt-10 ml-32">
            <span className="error-desc">
              Kindly follow these{' '}
              <a
                style={{ borderBottom: 'black 1px solid' }}
                href="https://confluence.nike.com/x/MUwqN"
                rel="noopener noreferrer"
                target="_blank"
              >
                steps
              </a>{' '}
              to raise access request using{' '}
              <a
                style={{ borderBottom: 'black 1px solid' }}
                href="https://idlocker.nike.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Idlocker
              </a>
              .
            </span>
          </H1>
          <H1 className="mt-10 ml-32">
            <span className="error-desc">
              In case of any queries, please raise a ticket to AIRMI-CUSTAPP-Support-L3 in{' '}
              <a
                style={{ borderBottom: 'black 1px solid' }}
                href="https://niketech.service-now.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Service-now
              </a>
              .
            </span>
          </H1>
          <H1 className="mt-10 ml-32">
            <span className="error-desc">
              For More Information, Please contact Lst-Digital.Library.Support@nike.com.
            </span>
          </H1>
        </Container>
      </div>
    );
  }
}

export default UserNotExistsError;
